import {Injectable} from '@angular/core';

declare let alertify: any;

@Injectable({
    providedIn: 'root'
})
export class AlertifyService {

    constructor() {
        alertify.set('notifier', 'position', 'bottom-left');
    }

    confirm(message: string, okCallback: () => any, cancelCallBack: () => any) {
        alertify.confirm(message, function () {
            okCallback();
        }, function () {
            cancelCallBack();
        }).setHeader('Confirm action');
    }

    success(message: string) {
        alertify.success(message);
    }

    error(message: string) {
        alertify.error(message);
    }

    warning(message: string) {
        alertify.warning(message);
    }

    message(message: string) {
        alertify.message(message);
    }
}
